.hero-footer{
  img{
    position: relative;
    top: 1px;
    margin-top: 6rem;
    vertical-align: bottom;
  }
}
.has-background-code{
  background-color: #1a1a1a;
  h1, h2{
    color: #fff;
    font-weight: 500;
    span{
      //font-weight: 900;
      //text-decoration: underline;
    }
  }
  button{
    margin-top: 3rem;
  }
}
.tag{
  font-weight: 700;
  margin-left: 15px;
}
